import { isSSR } from '@/src/lib/utils.ts';
import { useMediaQuery } from '@/src/hooks/useMediaQuery.ts';
import { useRequestMeta } from '@/src/lib/meta-request-context.ts';
import isMobile from 'is-mobile';
import { useEffect, useState } from 'react';

export default function useIsMobile() {
  const { userAgent } = useRequestMeta();
  const uaIsMobile = isMobile({ ua: userAgent });
  const [mobile, setMobile] = useState(uaIsMobile);

  const widthIsMobile = !isSSR() ? useMediaQuery('(max-width: 640px)') : false;

  useEffect(() => {
    if (mobile != widthIsMobile) {
      setMobile(widthIsMobile);
    }
  }, [widthIsMobile]);

  return mobile;
}
